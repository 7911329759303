var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stage-cards-component" } },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { staticClass: "mt-5" }, [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ])
        : [
            _c(
              "div",
              { staticClass: "stage-cards" },
              [
                _vm.showBackToOverview
                  ? _c("stageline-back-button", {
                      staticClass: "back-button",
                      attrs: { text: "Back to Overview" },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "cards-container" },
                  _vm._l(_vm.sortedStages, function (card) {
                    return _c(
                      "div",
                      {
                        key: card.stage,
                        on: {
                          click: function ($event) {
                            return _vm.navigate(card)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card",
                            class: {
                              "requires-attention-card":
                                card?.requiresAttention,
                            },
                          },
                          [
                            card?.requiresAttention
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "requires-attention-icon-div",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "requires-attention-icon-text",
                                      },
                                      [
                                        _vm._v(
                                          "\n                Action Required\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "image-container" },
                              [
                                card?.svg
                                  ? _c(card.svg, {
                                      tag: "component",
                                      staticClass: "image",
                                    })
                                  : _vm._e(),
                                card?.image
                                  ? _c("img", {
                                      staticClass: "image",
                                      attrs: {
                                        src: card.image,
                                        alt: `${card?.image}_image`,
                                      },
                                    })
                                  : _vm._e(),
                                card?.icon
                                  ? _c("stageline-navigation-icon", {
                                      staticClass: "image",
                                      attrs: {
                                        type: card.icon,
                                        height: 128,
                                        width: 128,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-container" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(card.title) +
                                    "\n              "
                                ),
                              ]),
                              _c("p", { staticClass: "description" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(card.description) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm.includeOtherOptions
              ? [
                  _c("h5", { staticClass: "hire-us-container-header" }, [
                    _vm._v("\n        Other Options\n      "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "hire-us-container",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("navigate-to-hire-us")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "hire-us-description" },
                        [
                          _c("span", [
                            _vm._v(
                              "\n            We offer payment processing, mail forwarding, virtual office, phone service, business,\n            supplies, and much more.\n          "
                            ),
                          ]),
                          _c("fa-icon", {
                            staticClass: "right-arrow",
                            attrs: { icon: "chevron-right" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }