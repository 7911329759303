<template>
  <div id="stage-cards-component">
    <ct-centered-spinner v-if="!loaded" class="mt-5">
      {{ loadingText }}
    </ct-centered-spinner>
    <template v-else>
      <div class="stage-cards">
        <stageline-back-button
          v-if="showBackToOverview"
          text="Back to Overview"
          class="back-button"
        />
        <div class="cards-container">
          <div
            v-for="card in sortedStages"
            :key="card.stage"
            @click="navigate(card)"
          >
            <div
              class="card"
              :class="{ 'requires-attention-card': card?.requiresAttention }"
            >
              <div v-if="card?.requiresAttention" class="requires-attention-icon-div ">
                <p class="requires-attention-icon-text">
                  Action Required
                </p>
              </div>

              <div class="image-container">
                <component
                  :is="card.svg"
                  v-if="card?.svg"
                  class="image"
                />

                <img
                  v-if="card?.image"
                  :src="card.image"
                  class="image"
                  :alt="`${card?.image}_image`"
                >

                <stageline-navigation-icon
                  v-if="card?.icon"
                  :type="card.icon"
                  class="image"
                  :height="128"
                  :width="128"
                />
              </div>
              <div class="text-container">
                <p class="title">
                  {{ card.title }}
                </p>
                <p class="description">
                  {{ card.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="includeOtherOptions">
        <h5 class="hire-us-container-header">
          Other Options
        </h5>
        <div class="hire-us-container" @click="$emit('navigate-to-hire-us')">
          <div class="hire-us-description">
            <span>
              We offer payment processing, mail forwarding, virtual office, phone service, business,
              supplies, and much more.
            </span>
            <fa-icon
              icon="chevron-right"
              class="right-arrow"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { completeInteraction } from '@/common/modules/clientInteractionLog'
  import { getProductRoute } from '@/components/HireUs/helper'
  import { makeToastMixin } from '@/mixins/makeToastMixin'
  export default {
    name: 'StageCards',
    components: {
      StagelineNavigationIcon:  () => import('@/components/StagelineV2/shared/StagelineNavigationIcon'),
      CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
      StagelineBackButton:      () => import('@/components/StagelineV2/shared/StagelineBackButton'),
    },

    mixins: [makeToastMixin],

    props: {
      cards: {
        type: Array,
        required: true,
      },
      clientInteractionLog: {
        type: Object,
        required: false,
      },
      includeOtherOptions: {
        type: Boolean,
        default: false,
      },
      showBackToOverview: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loaded: true,
        stagesRequiringAttention: 0,
        loadingText: 'Loading Stages...',
        stagesThatAreNavigable: [],
      }
    },

    computed: {
      ...mapGetters('stageline', [
        'company',
        'decisionTiles',
        'firstIncompleteSlideId',
        'stagelineLoaded',
      ]),
      ...mapGetters('products', [
        'products',
      ]),
      sortedStages() {
        return this.stagesThatAreNavigable.slice().sort((a, b) => {
          if (a?.requiresAttention && !b?.requiresAttention) {
            return -1 // Place cards that require attention first.
          } else if (!a?.requiresAttention && b?.requiresAttention) {
            return 1 // Place cards that don't require attention later.
          }
          return 0 // Keep the original order for cards with the same attention status.
        })
      },
    },

    async created() {
      this.loaded = false
      await this.filterStageCards()
      if (!this.stagesThatAreNavigable.slice().length) this.$emit('navigate-to-hire-us')
      this.loaded = true
    },
    methods: {
      ...mapActions('stageline', [
        'loadPeriod',
        'ghostMode',
      ]),
      ...mapActions('products', [
        'searchProductCategories',
        'getProducts',
      ]),
      async filterStageCards() {
        this.stagesThatAreNavigable = []

        for (let card of this.cards) {
          if (card?.hireUsProduct) {
            const result = await this.searchHireUsProductCategories(card)
            if (result?.category) this.stagesThatAreNavigable.push(card)
          } else {
            const stage = this.decisionTiles.find(tile => tile.name === card.stage)
            const findFirstSlide = stage?.id && !stage?.completed_at
            if (findFirstSlide && this.firstIncompleteSlideId({ type: 'stage', typeId: stage.id })) {
              this.stagesThatAreNavigable.push(card)
            }
          }
        }
      },
      async navigate(stageCard) {
        this.loadingText = stageCard?.hireUsProduct ? 'Loading...' : 'Loading Stages...'
        this.loaded = false

        if (stageCard?.hireUsProduct) {
          await this.navigateToHireUsProduct(stageCard)
        } else {
          await this.navigateToStage(stageCard)
        }

        this.loaded = true
      },

      async navigateToHireUsProduct(stageCard) {
        const result = await this.searchHireUsProductCategories(stageCard)

        if (!result || result.error) {
          this.errorToast('Error', 'Unable to load products')
          return
        }

        const category = result?.category
        const hasRequiredFields = category?.id && category?.external_product_bucket_id
        const categoryName = category?.name || stageCard?.hireUsProduct

        if (!hasRequiredFields) {
          this.warningToast('No Products/Categories',`No products/categories to show for category "${categoryName}"`)
          return
        }

        await this.loadProducts(category)

        if (this.products.length >= 1 ) {
          await this.routeToHireUsProduct(category, this.products, stageCard)
        } else {
          this.warningToast('No Products/Categories',`No products/categories to show for category "${categoryName}"`)
        }
      },
      async searchHireUsProductCategories(stageCard) {
        return await this.searchProductCategories({
          companyId: this.company.id,
          categoryName: stageCard?.hireUsProduct,
          limit: 1,
          matchType: 'exact',
        })
      },
      async loadProducts(category) {
        const params = {
          companyId: this.company.id,
          bucketId: category.external_product_bucket_id,
          categoryId: category.id,
        }
        await this.getProducts(params)
      },
      async routeToHireUsProduct(category, products, stageCard) {
        const categoryParams = {
          bucketId: category?.external_product_bucket_id,
          categoryId: category.id,
          name: category.name,
        }

        if (this.clientInteractionLog && !this.ghostMode) await this.logInteraction(stageCard)
        const route = await getProductRoute(products, categoryParams, this.company.id)
        await this.$router.push(route)
      },
      async navigateToStage(stageCard) {
        const stageId = this.decisionTiles.find(tile => tile.name === stageCard.stage).id

        const params = { companyId: this.company.id }
        params.periodName = this.currentPeriodName
        params.slideId = this.firstIncompleteSlideId({ type: 'stage', typeId: stageId })

        if (this.clientInteractionLog && !this.ghostMode) await this.logInteraction(stageCard, stageId)
        await this.$router.push({ name: 'stageline-v2-start', params })
      },
      async logInteraction(stageCard, stageId = null) {

        await completeInteraction({
          id: this.clientInteractionLog.id,
          interaction: {
            type: 'button',
            action: 'redirect',
            name: stageCard?.hireUsProduct || stageCard?.stage,
            action_required: stageCard?.requiresAttention || false,
          },
          completed: true,
          object_table: stageCard?.stage ? 'Stage' : null,
          object_id: stageId || null,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
 #stage-cards-component {
   .stage-cards {
     display: flex;
     flex-direction: column;
     max-width: 54rem;

     .back-button {
       text-align: left;
       padding-left: 1em;
     }

     .cards-container {
       display: flex;
       justify-content: center;
       flex-wrap: wrap;
       padding: 0;
       width: 100%;


       .card {
         @include ct-ui-selectable;
         @include ct-ui-card-shadow;

         border-radius: 0.625em;
         margin: 1rem;
         min-height: 20rem;
         max-width: 16rem;
         align-items: center;
         padding: 1rem !important;

         .image-container {
           margin: 1.5rem 0;
           height: 8rem;

           .image {
             height: 90%;
           }
         }

         .text-container {
           height: 100%;
           display: flex;
           flex-direction: column;
           justify-content: flex-start;

           .title {
             font-size: .9rem;
             font-weight: $ct-ui-font-weight-9;
           }

           .description {
             font-size: 0.875em;
             color: #404040;
             font-weight: $ct-ui-font-weight-5;
           }
         }
       }

       .requires-attention-card {
         border: solid 0.125em #f94b00 !important;
       }

       .requires-attention-icon-div {
         position: relative;
         top: -2.125rem;
         height: 0;
       }

       .requires-attention-icon-text {
         color: white;
         background-color: #f94b00;
         font-size: 1rem;
         padding: 0.25rem 1.5rem;
         border-radius: 6.25rem;
         font-style: italic;
         width: fit-content;
         margin: auto;
       }
     }
   }
   .hire-us-container-header {
     display: block;
     text-align: left;
     margin-top: 1rem;
     margin-bottom: 1rem;
     font-weight: $ct-ui-font-weight-9;
   }

   .hire-us-description {
     width: 100%;
     text-align: center;
   }

   .hire-us-container {
     @include ct-ui-selectable;
     @include ct-ui-card-shadow;

     display: flex;
     padding: 1.5rem 1.25rem 1.5rem 1.5rem;
     font-weight: $ct-ui-font-weight-5;
     text-align: left;
     max-width: 60rem;
     font-size: 0.875em;
     color: #404040;
     margin: 0 1rem 2rem 0;

     .right-arrow {
       font-size: 1rem;
       margin-left: 3rem;
       align-self: center;
     }
   }
 }

 @media only screen and (max-width: 768px) {
   #stage-cards-component {
     display: flex;
     flex-direction: column;
     align-items: center;

     .stage-cards {
       max-width: 18em;

       .back-button {
         text-align: left;
         padding-left: 1em;
       }
     }
   }
 }
</style>
